.checkbox {
    position: relative;
    display: inline-flex;
    align-items: center;

    font-size: 1em;
    color: var(--text-primary-light);
}

.checkbox input {
    cursor: pointer;
    appearance: none;
    margin: 3px 0.5em 3px 4px;

    width: 1em;
    height: 1em;

    font-size: 1em;

    background-color: #fff;
    border: 1px solid hsl(201, 12%, 77%);
    border-radius: 2px;

    transition: background-color .11s ease-in, border-color .11s ease-in;
}

.checkbox input:hover {
    border-color: hsl(164, 79%, 70%);
}

.checkbox input:focus {
    outline: hsl(164, 79%, 70%) solid 2px;
    border-color: hsl(164, 79%, 70%);
}

.checkbox input + .checked {
    display: none;
    margin: 3px 3px 3px 4px;
}

.checkbox input:checked {
    background-color: hsl(169.1, 100%, 36.7%);
    border-color: hsl(169.1, 100%, 36.7%);
}

.checkbox input:checked:hover {
    background-color: hsl(164, 79%, 70%);
    border-color: hsl(164, 79%, 70%);
}

.checkbox input:checked + .checked {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;

    display: inline-block;
    width: 1em;
    height: 1em;

    border: 1px solid transparent;
    border-radius: 2px;
    box-sizing: border-box;

    background: center/contain no-repeat url("data:image/svg+xml,%3Csvg width='10' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.97305 3.81395c-.3574-.34329-.92205-.34331-1.27948-.00004-.37843.36343-.37845.96874-.00003 1.3322l2.37324 2.27938c.32887.31586.85266.30128 1.16345-.03238l5.10699-5.48285c.36082-.38737.32458-.99772-.07955-1.33967-.37758-.3195-.93948-.28632-1.27685.07539L3.58333 5.36065l-1.61028-1.5467z' fill='%23fff'/%3E%3C/svg%3E");
    background-size: 0.7em 0.6em;

    transition: background-color .11s ease-in, border-color .11s ease-in;
}

.checkbox input:checked + .checked:hover {
    background-color: hsl(164, 79%, 70%);
    border-color: hsl(164, 79%, 70%);
}

.label {
    cursor: pointer;

    font-size: 1em;
}
