.search-block {
    position: relative;

    width: 100%;
    height: 2.5em;

    padding: 0;
    margin: 0;

    border: 1px solid hsl(201, 12%, 77%);
    border-radius: var(--primary-border-radius);
    background-color: #fff;

    overflow: hidden;

    transition: height .17s ease-in, box-shadow .17s ease;
}

.search-block_borderless {
    border: none;
}

.search-block:focus-within {
    box-shadow: 0 0 5px 1px hsla(176, 10%, 28%, .4);
}

.search-block input {
    font-size: 1em;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    padding: 0 calc(2em + 16px) 0 1em;

    border: none;
    outline: none;
}

.search-icon {
    position: absolute;
    right: 1em;
    top: 0.77em;
}

.suggestions-list {
    width: 100%;
    padding: 0;
    margin: 0;

    list-style-type: none;
}

.suggestion-item {
    cursor: pointer;

    min-height: 2em;

    padding: 0.5em 1em;
}

.suggestion-item:hover {
    background-color: rgba(170, 170, 170, .6);
}

.suggestion-item a {
    display: inline-block;
    height: 100%;

    border: none;
    text-decoration: none;

    /*font-size: 1.25em;*/
    line-height: 2em;
}

.suggestion-item a:link,
.suggestion-item a:visited,
.suggestion-item a:hover,
.suggestion-item a:active {
    color: #000;
}

.suggestion-item p {
    margin: .25em 0;
    font-size: .75em;
}
