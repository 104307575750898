.profile-icon {
    appearance: none;
    cursor: pointer;
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5em;

    padding: 0;

    font-size: 1em;

    border: 1px solid var(--border-primary-light);
    border-radius: 50%;
    box-sizing: content-box;

    background-color: transparent;
}

.profile-menu {
    list-style-type: none;

    position: absolute;
    right: 8em;
    top: var(--header-height);

    width: 200px;

    margin-top: 0.5em;
    padding: 0;

    background-color: #fff;
    border-radius: var(--primary-border-radius);

    box-shadow: 1px 1px 2px var(--border-primary-light);
}

.profile-menu::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 15px;

    display: block;
    width: 10px;
    height: 10px;

    background-color: #fff;

    transform: rotate(45deg);
}

.profile-menu li {
    position: relative;

    border-radius: var(--primary-border-radius);
}

.profile-menu li:hover {
    background-color: var(--bg-object-light);
}

.profile-menu li a {
    display: inline-block;
    width: 100%;
    height: 100%;

    padding: 0.5em 1em;

    color: var(--text-primary-light);
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
}

.profile-menu li button {
    width: 100%;
    height: 100%;

    padding: 0.5em 1em;
}
