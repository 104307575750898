.tag {
    display: inline-block;

    padding: 0.3em 0.7em;
    margin: 0 0.5em 0.5em 0;

    font-size: 0.8em;
    color: #4d5c69;
    background-color: #f8f8f8;

    border: 1px solid #999;
    border-radius: 2px;
}
