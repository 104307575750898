.content {
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

.banner {
  position: relative;
  height: 300px;

  padding: 60px 0;

  text-align: center;

  background: center/cover no-repeat url('./banner-bg.png');
  /*background: linear-gradient(135deg, var(--green), var(--light-green));*/
}

.banner-content {
  width: 80%;
  max-width: 900px;

  margin: 0 auto;

  z-index: 10;
}

.banner h1 {
  margin: 0.33em;

  font-family: 'Alien League', sans-serif;
  font-size: 3em;
  font-style: normal;
  font-weight: normal;
  color: var(--primary-default-light);
}

.banner h4 {
  margin: 0.2em;

  font-size: 1em;
  font-weight: 600;
}

.banner h1,
.banner h3 {
  letter-spacing: 0.2em;
}

.banner h3 {
  font-style: italic;
}

.banner-info {
  display: flex;
  align-items: center;

  background-color: #f5f8fe;
}

.banner .logo {
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 180px;
  height: 180px;

  background-color: #fff;
}

.banner .title {
  width: 100%;
}

.main .first-row {
  display: flex;
}

.about {
  margin-bottom: 32px;
}

.features {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  padding: 0 4em;

  background-color: hsl(220, 82%, 98%);
}

.feature {
  flex-basis: 30%;

  text-align: center;
}

.search {
  width: 100%;
  height: 6.5em;

  box-sizing: border-box;
  padding: 2em 40px;

  font-size: 18px;

  background-color: hsl(222, 57%, 95%);
}

.anchor {
  flex-shrink: 0;

  width: 20%;

  padding: 48px 2.5em 48px 110px;
  /*padding-right: 2.5em;*/

  background-color: hsl(222, 57%, 95%);
  font-family: 'Open Sans', sans-serif;
}

.anchor h2 {
  font-size: 2em;
  font-weight: 600;
  color: var(--text-header-light);
  line-height: 1.25em;
}

.anchor span {
  color: var(--text-primary-light);
}

@media all and (max-width: 600px) {
  .banner {
    height: auto;
  }

  .banner-info {
    flex-direction: column;
  }

  .banner .logo {
    display: none;
  }

  .title h1 {
    font-size: 2.35em;
  }

  .first-row {
    flex-direction: column;
  }

  .anchor {
    width: 100%;
    padding: 24px 8px 24px;
    box-sizing: border-box;
  }

  .features {
    flex-direction: column;
    padding: 12px 0 0 0;
  }
}
