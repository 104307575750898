.button {
    appearance: none;
    cursor: pointer;

    padding: 0 1.75em;

    font-size: 1em;
    color: var(--text-primary-light);
    background-color: transparent;

    border: 1px solid var(--text-header-light);
    border-radius: var(--primary-border-radius);
}

.borderless {
    border: none;
}
