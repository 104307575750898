.sidebar {
    min-width: 270px;
    margin-right: 16px;
}

.filter-list {
    list-style-type: none;

    padding-left: 0;
    margin-left: 0;
}
