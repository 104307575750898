.footer {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 4rem;
    box-sizing: border-box;

    padding: 16px 110px 0 110px;
}
