@import '../../vars.css';

.header {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;

  height: 4rem;

  padding-right: 10vw;

  background-color: #fff;
  box-shadow: 0 3px 7px rgba(170, 170, 170, 0.4);

  z-index: 1;
}

.logo {
  height: 65%;

  padding: 11px 2em 0 2em;
}

.block-link {
  display: inline-block;
  height: 100%;
  box-sizing: border-box;

  font-size: 0.9em;
  font-weight: 700;
  line-height: 4rem;
  white-space: nowrap;
  padding: 0 18px;
  color: var(--text-secondary-light);

  text-decoration: none;
  text-transform: uppercase;

  border-bottom: 4px solid #fff;

  transition: border-bottom-color 0.31s ease-in 0s;
}

.block-link:link,
.block-link:visited,
.block-link:hover,
.block-link:active {
  color: var(--text-secondary-light);
}

.block-link:hover {
  border-bottom: 4px solid var(--light-green);
}

.block-link-active:hover {
  border-bottom: 4px solid var(--green);
}

.block-link-active,
.block-link-active:visited {
  color: var(--text-header-light);
  border-bottom: 4px solid var(--green);
}

.right-side {
  display: flex;

  padding: 11px 0 12px 0;

  font-size: 16px;
}

.left-side {
  display: flex;
}

.search {
  margin-right: 1em;
}

.nav {
  display: flex;
}

@media all and (max-width: 1024px) {
  .search {
    display: none;
  }
}

@media all and (max-width: 600px) {
  .header {
    width: 100%;
    padding-right: 0;
  }

  .left-side {
    overflow: hidden;
  }

  .logo {
    height: auto;
    padding: 20px 14px;
  }

  .nav {
    overflow: scroll;
    width: 100%;
  }

  .right-side {
    display: none;
  }
}
