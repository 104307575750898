:root {
    --header-height: 4rem;
    --footer-height: 4rem;

    --primary-default-light: hsl(169, 100%, 37%);


    --text-header-light: #333333;

    --text-primary-light: #4D5C69;
    --text-secondary-light: #999999;


    --bg-page-light: #EFF3F8;

    --base3-active: #00B594;

    --border-primary-light: #BDC6CB;

    --bg-object-light: #F8F8F8;

    --primary-border-radius: 4px;

    --green: hsl(133, 44%, 51%);
    --light-green: hsl(133, 44%, 80%);

    --sky: hsl(201, 88%, 64%);
    --dark-sky: hsl(201, 88%, 35%);

    --nectarine: hsl(5, 88%, 64%);
}
