.page {
  display: grid;
  grid-template-columns: 110px 270px 1fr 1fr 1fr 10vw;
  grid-template-rows: 140px auto;
  grid-template-areas:
    '. header header header header .'
    '. sidebar content content content .';
  align-items: start;

  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  background-color: var(--bg-page-light);
}

.page-title {
  grid-area: header;
  margin: 0;
  padding: 2em 0 1em 0;
}

.filters {
  grid-area: sidebar;
  padding: 0 20px 64px 0;
}

.items-list {
  grid-area: content;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-column-gap: 1.2em;

  padding: 0;

  list-style-type: none;
}

.items-list li {
  margin: 0 0 1em 0;
}

.items-list-short li {
  max-width: none;
}

@media all and (max-width: 600px) {
  .page {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'header'
      'sidebar'
      'content';
  }

  .page-title {
    margin: 0.67em 0;
    padding: 0 8px;
  }

  .filters {
    margin: 0;
    padding: 0 8px;
  }
}
