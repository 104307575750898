.main {
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
    padding: 2em 110px;

    background-color: var(--bg-page-light);
}

.components-list {
    list-style-type: none;

    width: 60%;
    padding: 0;
}

.components-list li {
    margin: 2em 0;
}
