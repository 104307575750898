.readme {
    color: var(--text-primary-light);
}

.readme pre {
    border-radius: 2px;
}

.readme ul {
    list-style-type: none;

    padding-left: 15px;
}

.readme ul li:before {
    content: '—';
    margin-right: 5px;
}
