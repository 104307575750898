.item {
    cursor: pointer;

    height: 100%;
    max-width: 940px;
    box-sizing: border-box;

    padding: 2em 1.5em;

    color: var(--text-primary-light);;

    border-radius: var(--primary-border-radius);
    background-color: #fff;
    box-shadow: 0 2px 5px 2px rgba(170, 170, 170, .2);

    transition: transform .17s ease-in, box-shadow .17s ease-in;
}

.item:hover {
    transform: translate(1px, 2px);
    box-shadow: 1px 3px 5px 3px rgba(170, 170, 170, .3);;
}

.header {
    display: flex;
    align-items: center;
}

.logo {
    flex-shrink: 0;
    vertical-align: middle;

    max-width: 65px;
    max-height: 70px;
    margin: 1em 1em 1em 0;
}

.title {
    margin: 1em 0;
}

.info {
    min-width: 0;

    font-size: 0.8em;
    color: rgba(90, 90, 90, .9);
}

.info span {
    display: inline-block;
    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.description {
    margin: 1.5em 0;
}

.tags {
    margin-top: 1em;
}
