.banner h1 {
  font-size: 3em;
}

.banner h3 {
  font-size: 1.5em;
}

.banner h1,
.banner h3 {
  color: #fff;

  letter-spacing: 0.05em;
  text-shadow: 2px 2px 3px rgba(40, 40, 40, 0.6);
}

.banner h3 {
  font-style: italic;
}

.banner {
  height: 175px;

  padding: 32px 0 0 110px;

  background: linear-gradient(135deg, var(--sky), var(--light-green));
}

.content {
  width: 60%;

  padding: 50px 0 0 110px;
}

.inline-code {
  padding: 0.1em 0.5em;

  color: var(--text-header-light);
  background-color: var(--border-primary-light);

  border-radius: var(--primary-border-radius);
}

.list {
  list-style-type: none;

  padding: 0.5em 1.5em;
}

.list li {
  padding: 0.2em 0;
}

.list li:before {
  content: '—';
  margin-right: 5px;
}

.pseudo-link {
  appearance: none;
  cursor: pointer;

  margin: 0.5em 0;
  padding: 0.5em;

  font-size: 1em;

  border: none;
  border-radius: var(--primary-border-radius);
}

@media all and (max-width: 600px) {
  .banner {
    height: auto;
    padding: 0 8px;
  }

  .banner h1 {
    margin: 0;
    padding: 16px 0;
  }

  .content {
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
  }
}
