.component {
  width: 100%;
  max-width: 1200px;
  min-height: calc(100vh - var(--header-height) - var(--footer-height) - 32px);

  margin: 32px auto 0;
}

.component-name {
  height: 1.17em;
}

.component-version-time {
  color: var(--text-secondary-light);
}

.main {
  display: flex;
  width: 100%;
}

.item {
  width: 80%;
}

.sidebar {
  align-self: start;

  width: calc(37% - 3em);

  margin-left: 3em;
  padding: 0 1.5em;

  border-radius: var(--primary-border-radius);
  box-sizing: border-box;

  background-color: var(--bg-object-light);
}

.tabs {
  display: flex;
  justify-content: space-between;
  height: 40px;

  margin: 0 0 2em;
  padding: 0;

  list-style-type: none;

  border: 1px solid var(--border-primary-light);
  border-radius: var(--primary-border-radius);
}

.tabs li {
  cursor: pointer;
  display: inline-block;
  width: 100%;

  text-align: center;

  border: 2px solid white;
  border-radius: var(--primary-border-radius);

  transition: background-color 0.09s ease-in;
}

.tabs .tab-active,
.tabs li:hover {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tabs li.tab-active,
.tabs li:hover {
  background-color: var(--base3-active);
}

.tabs li button {
  cursor: pointer;

  display: block;
  width: 100%;
  height: 100%;

  font-size: 0.8em;
  text-decoration: none;
  appearance: none;
  border: none;
  background: none;
}

.tabs li:hover button {
  color: #fff;
}

.tabs li.tab-active button {
  color: #fff;
}

.code-string {
  appearance: none;

  display: block;
  width: 100%;
  margin: 0.5em 0;
  padding: 0;

  font-size: 1em;
  white-space: nowrap;
  border: none;
  background: transparent;
}

.code-string code {
  cursor: pointer;
  display: block;
  overflow-x: auto;

  padding: 1em;

  background: rgb(240, 240, 240) none repeat scroll 0 0;
  color: rgb(68, 68, 68);

  border-radius: var(--primary-border-radius);
}

.versions-button {
  height: 36px;
}

@media all and (max-width: 600px) {
  .component-name {
    height: auto;
    padding: 0 8px;
  }

  .component-version-time {
    padding: 0 8px;
  }

  .main {
    flex-direction: column-reverse;
  }

  .item {
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
  }

  .sidebar {
    width: 100%;
    margin: 0;
  }

  .tabs {
    width: calc(100% - 16px);
    margin: 0 auto 1em;
  }
}
