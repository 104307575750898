.list {
    list-style-type: none;

    padding: 0;
}

.list li:before {
    content: '—';
    margin-right: 5px;
}
